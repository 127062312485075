import {
  corporateSubscribe,
  subscribe,
} from "@goalteller/app-kit/api/rest/endpoints/public";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import BasicTextInput from "@goalteller/app-kit/components/forms/BasicTextInput";
import { MATCHERS } from "@goalteller/app-kit/data/regexs";
import analytics from "@goalteller/app-kit/tools/analytics";
import catchFormError from "@goalteller/app-kit/tools/catchFormError";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useRef, useState } from "react";

import image from "../images/newsletter.webp";
import arrow from "../images/tiltArrow.svg";
import AmpImage from "./AmpImage";
import WaveBgContainer from "./WaveBgContainer";
import styles from "./newsletter.module.css";

const DynamicNewsletterModal = dynamic(() => import("./NewsletterModal"));

export default function Newsletter() {
  const [inputEmail, setInputEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const email = useRef(null);
  const [success, setShowSuccess] = useState(false);
  const { pathname } = useRouter();
  const isCorporate = pathname.includes("corporate");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    analytics.setUserInfo({ email: inputEmail });
    analytics.setInfo({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Lead_Source: "Website - Newsletter",
    });
    setLoading(true);
    try {
      if (!inputEmail) {
        throw new Error("Please enter email");
      }
      if (!MATCHERS.email.test(inputEmail)) {
        throw new Error("This is not a valid email");
      }
      setEmailError("");
      await (isCorporate
        ? corporateSubscribe({
            data: { email: inputEmail, source: "corporate page" },
          })
        : await subscribe({ data: { email: inputEmail } }));

      analytics.setInfo({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Lead_Source: "Website - Newsletter",
      });
      setShowSuccess(true);
      setInputEmail("");
    } catch (error) {
      const catchedError = catchFormError(error);
      setEmailError(catchedError.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WaveBgContainer className={styles.wrapper} id="newsletter">
      <div className={styles.leftContainer}>
        <SectionTitle as="h2" className={styles.heading}>
          Cut through the clutter. Get what you need.
        </SectionTitle>
        <SectionTitle as="h3" className={styles.desc} size="xsmall">
          Get financial summaries, actionable insights and relevant knowledge in
          ONE weekly newsletter. We’re fluff-free and fact-full!
        </SectionTitle>
        <div className={styles.emailInputContainer}>
          <div className={styles.inputContainer}>
            <BasicTextInput
              className={styles.emailInput}
              innerClassNames={{ input: styles.input, label: styles.label }}
              label="Email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInputEmail(event.target.value);
              }}
              placeholder="Enter email address"
              ref={email}
            />
            <Button
              className={styles.joinButton}
              isBusy={loading}
              onClick={() => {
                void handleSubmit();
              }}
              variant="primary"
            >
              Join Now{" "}
              <AmpImage
                alt="newsletter arrow"
                className={styles.arrow}
                src={arrow}
              />
            </Button>
          </div>
          {!!emailError && <div className={styles.errorMsg}>{emailError}</div>}
          <div className={styles.subscribers}>
            More than 20k readers and over 75k recipients
          </div>
          <DynamicNewsletterModal
            setShowSuccess={setShowSuccess}
            success={success}
          />
        </div>
      </div>

      <div className={styles.rightContainer}>
        <div className={styles.newsletterImageContainer}>
          <AmpImage
            alt="A man sending a email to goalteller"
            className={styles.newsletterImage}
            src={image}
            width={460}
          />
        </div>
      </div>
    </WaveBgContainer>
  );
}
