import type {
  BlogArticleResponse,
  BlogMediaArticleResponse,
} from "@goalteller/app-kit/api/rest/endpoints/blog/responses";

import PageLoader from "@goalteller/app-kit/components/loaders/PageLoader";
import { UI_DANISH_DATE_FORMAT } from "@goalteller/app-kit/tools/constants";
import { IconCalendar } from "@tabler/icons-react";
import clsx from "clsx";
import { format } from "date-fns";
import Link from "next/link";
import { useState } from "react";

import { CategoryWrapper } from "../../wrappers/div";
import AmpImage from "../AmpImage";
import styles from "./blogCard.module.css";

const MAX_SUBTITLE_WORDS = 20;
const MAX_TITLE_WORDS = 12;

const getSubtitle = (value?: string) =>
  value?.split(" ").slice(0, MAX_SUBTITLE_WORDS).join(" ");
const getTitle = (value?: string) =>
  value?.split(" ").slice(0, MAX_TITLE_WORDS).join(" ");

export default function BlogCard({
  className,
  item,
}: {
  className?: string;
  item?: BlogArticleResponse;
}) {
  const [loading, setLoading] = useState(false);
  if (loading) {
    return (
      <div className={styles.container}>
        <PageLoader />
      </div>
    );
  }

  if (!item) {
    return;
  }
  return (
    <Link
      href={`/blog/${item.slug}`}
      onClick={() => {
        setLoading(true);
      }}
    >
      <div className={clsx(styles.wrapper, className)}>
        <div>
          <div className={styles.blogImageContainer}>
            {!!item.thumbnail && (
              <AmpImage
                alt="Blog thumbnail"
                className={styles.blogImage}
                height={180}
                src={item.thumbnail}
                width={330}
              />
            )}
            {!!item.tag && (
              <CategoryWrapper className={styles.blogCategory}>
                {item.tag}
              </CategoryWrapper>
            )}
          </div>
          <div className={styles.blogTitle}>
            {!!item.title && getTitle(item.title)}
            ...
          </div>
        </div>
        <div className={styles.blogDesc}>
          <div className={styles.dateTimeContainer}>
            <div className={styles.blogDate}>
              <IconCalendar className={styles.icons} />
              <span>
                {item.published_date
                  ? format(item.published_date, UI_DANISH_DATE_FORMAT)
                  : "N/A"}
              </span>
            </div>
          </div>
          <div className={styles.blogAuthor}>
            By <span className={styles.blogAuthorName}>{item.author_name}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export function NewsCard({ item }: { item: BlogMediaArticleResponse }) {
  return (
    <div className={clsx(styles.wrapper, styles.newsWrapper)}>
      <div className={styles.infoContainer}>
        <AmpImage
          alt="Blog thumbnail"
          height={25}
          src={item.thumbnail}
          width={75}
        />
        <div className={styles.newsTitle}>{getTitle(item.title)}...</div>
        <div className={styles.subtitle}>
          {getSubtitle(item.description)} ...
        </div>
      </div>
      <div className={clsx(styles.blogDesc, styles.blogAction)}>
        <div className={styles.dateTimeContainer}>
          <div className={styles.blogDate}>
            <IconCalendar className={styles.icons} />
            <span>
              {item.published_date.split("-").toReversed().join("/") || "N/A"}
            </span>
          </div>
        </div>
        <Link className={styles.link} href={item.content_url} target="_blank">
          View More
        </Link>
      </div>
    </div>
  );
}
