import type { ReactNode } from "react";

import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import clsx from "clsx";

import styles from "./waveBgContainer.module.css";

export default function WaveBgContainer({
  children,
  className,
  id = "",
}: {
  children: ReactNode;
  className: string;
  id?: string;
}) {
  return (
    <div className={styles.root} id={id}>
      <MaxWidthContainer className={clsx(styles.wrapper, className)} isSection>
        {children}
      </MaxWidthContainer>
    </div>
  );
}
