import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import generateCssWrapper from "@goalteller/app-kit/ye-design/wrappers/generateCssWrapper";

import styles from "./header.module.css";

export const BannerHeader = generateCssWrapper(styles.banner, SectionTitle);
export const BannerSubHeader = generateCssWrapper(
  styles.bannerSub,
  SectionTitle,
);

export const BannerTitle = generateCssWrapper(styles.bannerTitle);
