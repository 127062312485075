import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import generateCssWrapper from "@goalteller/app-kit/ye-design/wrappers/generateCssWrapper";

import AmpImage from "../../AmpImage";
import styles from "./testimonialsWrapper.module.css";

export const TestimonialHeadline = generateCssWrapper(styles.headline);
export const TestimonialContent = generateCssWrapper(styles.content);
export const TestimonialName = generateCssWrapper(styles.name);
export const TestimonialDesignation = generateCssWrapper(styles.designation);

export const TestimonialImageOverlay = generateCssWrapper(styles.imageOverlay);
export const TestimonialImage = generateCssWrapper(styles.img, AmpImage);
export const TestimonialPlayButton = generateCssWrapper(
  styles.playButton,
  Button,
);
export const TestimonialPlay = generateCssWrapper(styles.play, AmpImage);
