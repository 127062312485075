import type { BlogMediaArticleResponse } from "@goalteller/app-kit/api/rest/endpoints/blog/responses";
import type { Dispatch, SetStateAction } from "react";

import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import clsx from "clsx";

import play from "./../images/play.svg";
import AmpImage from "./AmpImage";
import { TestimonialPlay } from "./HomePage/Testimonials/TestimonialsWrapper";
import styles from "./podcastTile.module.css";

interface PodcastProps {
  innerClassName?: {
    containerStyles?: string;
    imageStyles?: string;
    playButtonImageStyles?: string;
    playButtonStyles?: string;
    titleStyles?: string;
  };
  playing: boolean;
  setPlaying: (value: boolean) => void;
  setVideoLink: Dispatch<SetStateAction<string | undefined>>;
  video?: BlogMediaArticleResponse;
}

export default function PodcastTile({
  innerClassName,
  playing,
  setPlaying,
  setVideoLink,
  video,
}: PodcastProps) {
  return (
    <Button
      className={clsx(styles.imageContainer, innerClassName?.containerStyles)}
      onClick={() => {
        setPlaying(!playing);
        setVideoLink(video?.content_url);
      }}
      variant="borderless"
    >
      <AmpImage
        alt=""
        className={clsx(styles.image, innerClassName?.imageStyles)}
        height="500"
        src={video?.thumbnail ?? ""}
        width="500"
      />
      <div className={clsx(styles.title, innerClassName?.titleStyles)}>
        {video?.title}
      </div>
      {!!video?.content_url && (
        <div
          className={clsx(styles.playButton, innerClassName?.playButtonStyles)}
        >
          <TestimonialPlay
            alt="play button icon"
            className={innerClassName?.playButtonImageStyles}
            src={play}
            width={60}
          />
        </div>
      )}
    </Button>
  );
}
