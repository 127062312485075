import { IconX } from "@tabler/icons-react";

import {
  CrossButton,
  Overlay,
  VideoPlayer,
  VideoWrapper,
} from "../../../wrappers/videoPlayer";

interface VideoFrameProps {
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  videoLink?: string;
}

const VideoFrame: React.FC<VideoFrameProps> = ({
  playing,
  setPlaying,
  videoLink,
}) => {
  return (
    <>
      <CrossButton
        onClick={() => {
          setPlaying(!playing);
        }}
        variant="borderless"
      >
        <IconX />
      </CrossButton>
      <Overlay
        onClick={() => {
          setPlaying(!playing);
        }}
      />
      <VideoWrapper>
        <VideoPlayer
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          src={videoLink}
          title="YouTube video player"
        />
      </VideoWrapper>
    </>
  );
};

export default VideoFrame;
